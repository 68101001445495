<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-text class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logoarty/LOGO ARTY SYSTEM4.png')"
              width="200px"
              alt="logo"
              contain
              class="my-0"
            ></v-img>
          </router-link>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="Usuario"
              dense
              placeholder="Usuario"
              hide-details
              class="mb-3"
              v-on:keyup.enter="onEnter"
            >
            </v-text-field>

            <v-text-field
              class="mb-3"
              ref="passwordref"
              v-model="password"
              outlined
              dense
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Contraseña"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              v-on:keyup.enter="onEnter"
            >
            </v-text-field>

            <v-select
              v-model="empresaSelect"
              :items="empresas"
              item-text="razonSocial"
              item-value="id"
              dense
              outlined
              :loading="cargandoEmpresas"
              label="Empresas"
            ></v-select>
            <div class="d-flex align-center justify-space-between flex-wrap">
              <!--  <v-checkbox label="Recuerdame" hide-details class="me-3 mt-1"> </v-checkbox>

               forgot link -->
              <a href="javascript:void(0)" class="mt-1"> ¿Olvido su contraseña? </a>
            </div>

            <v-btn
              :loading="loading"
              :disabled="loading"
              block
              color="primary"
              class="mt-6"
              v-on:click="IniciarSesion()"
            >
              Iniciar Sesión
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">by</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
          <a href="http://artysys.com">© ArtyTechnology</a>
          <br />
        </v-card-actions>
        <p class="text-center">V.{{ VERSION }}</p>
      </v-card>
    </div>

    <!-- background triangle shape  -->

    <v-snackbar v-model="isSnackbarVisible" multi-line :light="$vuetify.theme.dark">
      {{ mensaje }}

      <template #action="{ attrs }">
        <v-btn color="error" text v-bind="attrs" @click="isSnackbarVisible = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import axios from '@/api'
import config from '@/api/config'
import EmpresaServices from '@/api/servicios/EmpresaServices'
import SeguridadServices from '@/api/servicios/SeguridadServices'
export default {
  setup() {
    const isSnackbarVisible = ref(false)
    const mensaje = ref('')
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const VERSION = process.env.VUE_APP_VERSION
    const loading = ref(false)
    const mesoneros = ref([])
    const passwordref = ref(null)
    const cargandoEmpresas = ref(false)
    const empresas = ref([])
    const empresaSelect = ref({})
    const inicializar = ref(false)

    onBeforeMount(() => {
      CargarEmpresas()
    })
    const CargarEmpresas = () => {
      cargandoEmpresas.value = true

      try {
        EmpresaServices.EmpresaConsultarLogin()
          .then(response => {
            if (response.data.estatus == true) {
              empresas.value = response.data.datos
              empresaSelect.value = empresas.value[0].id
            } else {
              if (response.data.mensaje == 'NO INICIALIZADO') {
                router.push('/pages/inicializar')
              } else if (response.data.mensaje == 'SIN BASE DE DATOS') {
                router.push('/pages/inicializardb')
              } else if (response.data.mensaje == 'DESACTUALIZADO') { 
                router.push({ name: 'pages-actualizar', params: { version: JSON.stringify(response.data.datos)  } })
              } else {
                isSnackbarVisible.value = true
                mensaje.value = response.data.mensaje
              }
            }
          })
          .catch(err => {
            isSnackbarVisible.value = true
            mensaje.value = 'Error General' + err
          })
          .finally(() => {
            cargandoEmpresas.value = false
          })
      } catch (error) {
        cargandoEmpresas.value = false
        isSnackbarVisible.value = true
        mensaje.value = 'Error General'
      }
    }

    const IniciarSesion = () => {
      console.log('login')

      //store.commit('setUserData', { id: 1, userName: 'angel labori', autenticate: true })
      //router.push('/')
      loading.value = true
      SeguridadServices.IniciarSesion({
        nombreUsusario: email.value,
        clave: password.value,
        IdEmpresa: empresaSelect.value,
      })
        .then(function (response) {
          loading.value = false
          if (response.data.mensaje == 'OK') {
            store.commit('setUserData', {
              ...response.data.datos,
              autenticate: true,
              IdEmpresa: empresaSelect.value.id,
            })

            // DatosConfiguracion()
            router.push('/')
          } else {
            isSnackbarVisible.value = true
            mensaje.value = response.data.mensaje
          }
          console.log(response)
        })
        .catch(function (error) {
          loading.value = false
          isSnackbarVisible.value = true
          mensaje.value = 'Ups, disculpe hubo un error.'
          console.error(error)
        })
    }

    const onEnter = () => {
      IniciarSesion()
    }
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isSnackbarVisible,
      isPasswordVisible,
      email,
      password,
      socialLink,
      IniciarSesion,
      store,
      mensaje,
      loading,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      onEnter,
      mesoneros,
      config,
      passwordref,
      empresas,
      cargandoEmpresas,
      empresaSelect,
      VERSION
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.contain-mesonero {
  height: 100vh;
  overflow-y: auto;
}
</style>
